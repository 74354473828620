import { MainLayout } from "components";
import { AnimatedParagraph } from "components/elements";
import { OurPartners } from "pages/home";
import React from "react";

export const About = () => {
  return (
    <MainLayout>
      <div className="min-h-screen flex items-center justify-center relative">
        <img
          src="https://res.cloudinary.com/dj8ezastp/image/upload//c_thumb,w_1600,h_900,g_auto/v1694508848/WhatsApp_Image_2023-09-12_at_11.22.24_aypgnj.jpg"
          alt="About"
          className="absolute inset-0 object-cover w-full h-full"
        />

        <div className="absolute inset-0 bg-black opacity-50"></div>

        <div className="text-white text-center z-10">
          <h1 className="font-black text-5xl mb-4">About Us</h1>
          <h1 className="text-xl md:text-3xl">
            Working hard to reduce the carbon emissions of the transport
            industry
          </h1>
        </div>
      </div>

      <div className="min-h-screen flex items-center justify-center relative">
        <img
          src="https://res.cloudinary.com/dj8ezastp/image/upload/v1694246396/zembo_m8o2ps.jpg"
          alt="About"
          className="absolute inset-0 object-cover w-full h-full"
        />

        <div className="absolute inset-0 bg-black opacity-50"></div>

        <div className="text-white z-20 mx-8 md:-ml-30 p-8 md:p-0 w-[1080px]">
          <div className="text-2xl md:text-4xl font-bold mb-4">
            Solar Powered Battery Swapping Stations
          </div>
          <div className="text-xl md:text-3xl">
            <AnimatedParagraph>
              Zembo is leading the African transition to electric mobility.
              Based in Kampala, Uganda, we provide affordable (PayGo) electric
              two-wheelers to low-income motorcycle-taxi drivers, most known
              locally as “boda-boda ”drivers. We offer a battery swapping
              service to our clients, enabling to swap within minutes at any of
              our stations.
            </AnimatedParagraph>
          </div>
        </div>
      </div>

      <div className="bg-black">
        <div className="flex flex-col md:flex-row items-center">
          <div>
            <img
              src="https://res.cloudinary.com/dj8ezastp/image/upload/v1694247987/ffff_sfm3ft.jpg"
              alt="Man assembling a motocycle"
              className="md:w-[6500px]"
            />
          </div>
          <div className="text-white mx-8 md:mx-24 p-8 md:p-0">
            <div className="text-2xl md:text-4xl font-bold mb-4">
              A Growing Industry
            </div>
            <div className="text-xl md:text-3xl">
              <AnimatedParagraph>
                In Uganda, boda-bodas provide transportation for 60% of the
                population. In the country, more than 600,000 self-employed
                drivers are vulnerable to fuel price fluctuation. Zembo works
                hard to increase riders savings by reducing the expense on the
                fuel.
              </AnimatedParagraph>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-black">
        <div className="flex flex-col md:flex-row items-center">
          <div className="text-white mx-8 md:mx-24 p-8 md:p-0">
            <div className="text-2xl md:text-4xl font-bold mb-4">
              Promoting Gender Inclusion
            </div>
            <div className="text-xl md:text-3xl">
              <AnimatedParagraph>
                Zembo is committed to being a gender inclusive organisation: 30%
                of the team are women and we actively empower female clients
                through training. The company strives hard to promote
                employability of both genders.
              </AnimatedParagraph>
            </div>
          </div>
          <div>
            <img
              src="https://res.cloudinary.com/dj8ezastp/image/upload//c_thumb,w_1098,h_731,g_auto/v1694247988/zembos2_ffek1b.jpg"
              alt="Man assembling a motocycle"
              className="md:w-[4000px]"
            />
          </div>
        </div>
      </div>

      <div className="min-h-screen flex items-center justify-center relative">
        <img
          src="https://res.cloudinary.com/dj8ezastp/image/upload/v1695125125/bikes_dgrmmz.jpg"
          alt="About"
          className="absolute inset-0 object-cover w-full h-full"
        />

        <div className="absolute inset-0 bg-black opacity-50"></div>

        <div className="text-white z-20 mx-8 md:-ml-30 p-8 md:p-0 w-[1080px]">
          <div className="text-2xl md:text-4xl font-bold mb-4">
            Our Environmental Impact
          </div>
          <div className="text-xl md:text-3xl">
            <AnimatedParagraph>
              100 million tonnes of CO2 would be saved each year if all
              boda-bodas in the country were electric. With huge amounts of
              carbon being emitted into the environment, Zembo utilises solar
              power to create energy and in turn works hard to reducing these
              emissions to zero.
            </AnimatedParagraph>
          </div>
        </div>
      </div>
      <OurPartners />
      <div className="bg-black text-white">
        <div className="text-2xl md:text-4xl font-bold mb-4 text-center py-8 pt-12">
          Zembo Press
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3">
          <iframe
            allowFullScreen="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            title="Uganda: Kampala gears up for e-mobility | Global Ideas"
            src="https://www.youtube.com/embed/fSjzZ44Kmjo?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=http%3A%2F%2Fwww.zem.bo&amp;widgetid=1"
            id="widget2"
            width="100%"
            height="300px"
            frameBorder="0"
          ></iframe>
          <iframe
            allowFullScreen="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            title="How boda riders are embracing battery power"
            src="https://www.youtube.com/embed/bC3ij-OVoJI?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=http%3A%2F%2Fwww.zem.bo&amp;widgetid=5"
            id="widget6"
            width="100%"
            height="300px"
            frameBorder="0"
          ></iframe>
          <iframe
            width="100%"
            height="300px"
            src="https://www.youtube.com/embed/Wvy8oEPMX5M"
            title="Zembo Bikes Uganda"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen=""
          ></iframe>
          <iframe
            width="100%"
            height="300px"
            src="https://www.youtube.com/embed/Q4Btt1Qwkcs"
            title="Is the Electric Boda Boda a viable alternative to gasoline engines?"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen=""
          ></iframe>
          <iframe
            width="100%"
            height="300px"
            src="https://www.youtube.com/embed/4Z3wmAxxzBQ"
            title="The Electric Bike"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen=""
          ></iframe>
          <iframe
            width="100%"
            height="300px"
            src="https://www.youtube.com/embed/oJcUjgqZMSM"
            title="Inside Uganda’s Electric Motorcycle Assembling Plant"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen=""
          ></iframe>
        </div>
      </div>
    </MainLayout>
  );
};
