import React from "react";
import { MainLayout } from "components";
import { HiMail } from "react-icons/hi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { RiMapPinFill } from "react-icons/ri";

export const Contact = () => {
  return (
    <MainLayout>
      <div className="min-h-screen flex items-center justify-center relative">
        <img
          src="https://res.cloudinary.com/dj8ezastp/image/upload/v1694333029/contacts_yayrv0.jpg"
          alt="Contact Us"
          className="absolute inset-0 object-cover w-full h-full"
        />

        <div className="absolute inset-0 bg-black opacity-50"></div>

        <div className="text-white text-center z-10">
          <div className="flex flex-col items-start space-y-2">
            <h1 className="font-black text-5xl mb-4">Contact us</h1>
            <div className="flex flex-row items-center space-x-2">
              <HiMail className="h-8 w-8" /> <span>contact@zem.bo</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
              <BsFillTelephoneFill className="h-8 w-8" />{" "}
              <span>+256 200 910400 / +256 393 300400</span>
            </div>
            <div className="flex flex-row items-center space-x-2">
              <RiMapPinFill className="h-8 w-8" />{" "}
              <span>Plot 56, Muteesa 2 Road, Ntinda, Kampala</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <iframe
          loading="lazy"
          src="https://maps.google.com/maps?q=Plot%2056%2C%20Muteesa%202%20Road&amp;t=m&amp;z=11&amp;output=embed&amp;iwloc=near"
          width="100%"
          height="800px"
          frameborder="0"
          style={{
            position: 'relative',
            border: 'none',
            width: '100%'
          }}
        ></iframe>
      </div>
    </MainLayout>
  );
};
