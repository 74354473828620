import React from "react";
import { BsFacebook, BsLinkedin, BsTwitter } from "react-icons/bs";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="bg-black text-white py-8">
      <div className="text-center mb-12">
        <h2 className="text-4xl font-bold mb-12">Follow us</h2>
        <div className="flex justify-center mt-2 text-xl">
          <a
            href="https://twitter.com/gozembo"
            target="_blank"
            className="mx-2"
          >
            <BsTwitter className="" />
          </a>
          <a
            href="https://www.facebook.com/gozembo"
            target="_blank"
            className="mx-2"
          >
            <BsFacebook className="" />
          </a>
          <a
            href="https://www.linkedin.com/company/zembo/"
            target="_blank"
            className="mx-2"
          >
            <BsLinkedin className="" />
          </a>
        </div>
      </div>

      <div className="flex justify-center items-center text-sm text-gray-400">
        <p>&copy; Zembo 2023</p>
        <div className="flex">
          <Link to="/" className="mx-2">
            Home
          </Link>
          <Link to="/about" className="mx-2">
            About us
          </Link>
          <Link to="/team" className="mx-2">
            Our team
          </Link>
          <Link to="/contact" className="mx-2">
            Contact
          </Link>
        </div>
      </div>
    </footer>
  );
};
