import React, { useState, useEffect } from "react";
import LogoImg from "assets/images/zembo.png";
import { Link } from "react-router-dom";
import { SlClose, SlMenu } from 'react-icons/sl';

export const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`${isScrolled ? 'bg-black' : 'bg-transparent'} fixed w-full z-20 transition-all duration-300`}
    >
      <div className="w-full text-gray-700">
      <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
          <div className="flex flex-row items-center justify-between p-4">
            <Link
              to="/"
              className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline"
            >
              <img src={LogoImg} alt="Zembo" className="h-10 mr-2" />
            </Link>
            <button
              className="rounded-lg md:hidden focus:outline-none focus:shadow-outline"
              onClick={() => setOpen(!open)}
            >
              {open ? <SlClose className="w-6 h-6 text-white" /> : <SlMenu className="w-6 h-6 text-white" />}
            </button>
          </div>
          <nav
            className={`${
              open ? "flex bg-white p-4" : "hidden"
            } md:flex flex-col md:flex-row md:justify-end`}
          >
            <Link
              to="/"
              className="px-4 py-2 mt-2 text-md md:text-white uppercase font-semibold bg-transparent md:mt-0 md:ml-4 border-none md:border-b"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="px-4 py-2 mt-2 text-md md:text-white uppercase font-semibold bg-transparent md:mt-0 md:ml-4 border-none md:border-b"
            >
              About Us
            </Link>
            <Link
              to="/team"
              className="px-4 py-2 mt-2 text-md md:text-white uppercase font-semibold bg-transparent md:mt-0 md:ml-4 border-none md:border-b"
            >
              Our Team
            </Link>
            <Link
              to="/contact"
              className="px-4 py-2 mt-2 text-md md:text-white uppercase font-semibold bg-transparent md:mt-0 md:ml-4 border-none md:border-b"
            >
              Contact
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

