import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const ClientSlider = () => {
  const clients = [
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/ADEME-pvfipp6gcf4ulbpa6hy87l015au2w6belqr03fnqbk.png",
      alt: "ADEME",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/AFD-pvfipp6gcf4ulbpa6hy87l015au2w6belqr03fnqbk.png",
      alt: "AFD",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/DOB_Logo-pvfipr24q37f8jmjvirhckiyc2ktbkiva01z1zkxz4.png",
      alt: "DOB Equity",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/EEP-pvfipr24q37f8jmjvirhckiyc2ktbkiva01z1zkxz4.png",
      alt: "EEP",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/FFEM-logo-pvfipsxt3r9zvrjtkjkqhk1viubjqyqby9cy0ji5mo.png",
      alt: "FFEM",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/GIZ-pvfipwp5v3f567ecyl78rj3pwdt0lr59aryvxnckxs.png",
      alt: "GIZ",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/Infraco-pvfiqowbk4hqui9edxe1ubzjpxy10o97enjgby6rr4.png",
      alt: "InfraCo Africa",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/KCCA-pvfj7clcofaolq2b44m114nmpru7fkdyd3r8ihhlhc.png",
      alt: "KCCA",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/MAN-Impact-pvfj7eh123d98xzkt5fa646jwjkxuylf1d27h1et4w.png",
      alt: "MAN Impact",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/MEMD-LOGO-pvfj7lzqkrnjttonl8oaq2a8nmjvkjf9qea3b93nr4.jpg",
      alt: "MEMD",
    },
    {
      imageSrc:
        "https://res.cloudinary.com/dj8ezastp/image/upload/v1695377598/R_np66he_Thumbnail_ws5snl.jpg",
      alt: "MEMD",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/MOBILITY54LOGOVERTICAL-pvfj7tig3fxuepdqdbxba0dxepita494ffhz5gsidc.png",
      alt: "mobility 54",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/PREO-pvfj7ve4h40f1xb02cqkezwulh9jpigl3osy40pq0w.png",
      alt: "PREO",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/UIA-pvfj86o6r4fux8um8hm38x2dq3py9vpd58mrvc8zy8.png",
      alt: "UIA",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/usaid-png-pvfj8afjigl07op5mj8liw483n7f4o4ahr8psg3f9c.png",
      alt: "USAID",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/USEA-pvfj8e6w9sq5i4jp0kv3sv62h6ovzgj7u9unpjxukg.png",
      alt: "USEA",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/TUGENDE-pvfj84sidgdaa0xcjgsu3xjgjbz7uhhwgzbswsbsao.png",
      alt: "Tugende",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/1200x680_logo-ara-rvb_bleu2-q1687t9qpfzajijzppb8jxq8oouja8dmahvjtkdwxs.jpg",
      alt: "AURA",
    },
    {
      imageSrc:
        "http://www.zem.bo/wp-content/uploads/elementor/thumbs/download-q1689jb38ic7ts1vng4m4i2nw4cre97wj0xlhttxj4.png",
      alt: "Yunus",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 400,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {clients.map((client, index) => (
        <div key={index} className="">
          <img
            decoding="async"
            className="swiper-slide-image"
            src={client.imageSrc}
            alt={client.alt}
          />
        </div>
      ))}
    </Slider>
  );
};
