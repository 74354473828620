import React from "react";

import { Carousel } from "components/elements";

export const TeamMates = () => {
  const teammates = [
    {
      imageSrc:
        "https://res.cloudinary.com/dj8ezastp/image/upload/v1694273303/zembin_hoe3k1.jpg",
      team: "Tech Team",
    },
    {
      imageSrc:
        "https://res.cloudinary.com/dj8ezastp/image/upload/v1695378329/sales1_pfaqkt.jpg",
      team: "Sales Team",
    },
    {
      imageSrc:
        "https://res.cloudinary.com/dj8ezastp/image/upload/v1694331069/IT_xihooh.jpg",
      team: "IT Team",
    },
    {
      imageSrc:
        "https://res.cloudinary.com/dj8ezastp/image/upload/v1694331879/ops_sywqo8.jpg",
      team: "Ops Team",
    },
    {
      imageSrc:
        "https://res.cloudinary.com/dj8ezastp/image/upload/v1694332235/team_itgcqm.jpg",
      team: "Zembo Team",
    },
    {
      imageSrc:
        "https://res.cloudinary.com/dj8ezastp/image/upload/v1691066235/bike-test_bub3w2.jpg",
      team: "Founders",
    },
  ];

  return (
    <div className="bg-black md:px-40">
      <Carousel images={teammates.map(teammate => teammate.imageSrc)} />
    </div>
  );
};
