import { About } from "pages/about";
import { Contact } from "pages/contact";
import { Home } from "pages/home";
import { Team } from "pages/team";
import { useRoutes } from "react-router-dom";

export const AppRoutes = () => {
  const commonRoutes = [
    { path: "/", element: <Home /> },
    { path: "/about", element: <About /> },
    { path: "/team", element: <Team /> },
    { path: "/contact", element: <Contact /> },
];

  const element = useRoutes([...commonRoutes]);

  return <>{element}</>;
};
