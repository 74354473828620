import React, { useEffect, useRef, useState } from 'react';

export const AnimatedParagraph = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const fadeInRef = useRef();

  const handleScroll = () => {
    if (fadeInRef.current) {
      const top = fadeInRef.current.getBoundingClientRect().top;
      setIsVisible(top <= window.innerHeight * 0.75);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <p ref={fadeInRef} className={`fade-in ${isVisible ? 'is-visible' : ''}`}>
      {children}
    </p>
  );
};

