import { MainLayout } from "components";
import React from "react";
import { OurPartners } from "../components/OurPartners";
import { AnimatedParagraph } from "components/elements";

export const Home = () => {
  return (
    <MainLayout>
      <div className="min-h-screen flex items-center justify-center relative">
        <video
          autoPlay
          muted
          loop
          className="absolute inset-0 object-cover w-full h-full"
        >
          <source
            src="https://res.cloudinary.com/dj8ezastp/video/upload/v1695367092/90_Sec_Clip_V2_Wesbite_nosubs_1_online-video-cutter.com_1_veelag.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <div className="absolute inset-0 bg-black opacity-50"></div>

        <div className="text-white text-center z-10">
          <h1 className="text-4xl sm:text-5xl md:text-8xl font-bold mb-4">
            Zembo
          </h1>
          <p className="text-xl md:text-3xl">
            Uganda's E-mobility Solutions Company
          </p>
        </div>
      </div>
      <div className="bg-black">
        <div className="flex flex-col md:flex-row items-center">
          <div>
            <img
              src="https://res.cloudinary.com/dj8ezastp/image/upload/v1691066235/Tech_MC_assembly_3_dcxqqj.jpg"
              alt="Man assembling a motocycle"
              className="w-[1000px] md:w-[2900px]"
            />
          </div>
          <div className="text-white mx-8 md:mx-24 p-8 md:p-0">
            <div className="text-2xl md:text-4xl font-bold mb-4">
              Introducing E-motorcycles in Africa.
            </div>

            <div className="text-xl md:text-3xl ">
              <AnimatedParagraph>
                We provide affordable state-of-the-art electric motorcycles that
                are locally assembled in Uganda. By combining up to 200kms of
                estimated range with Zembo fast charging technology, you spend
                less time charging and even more time on the road.
              </AnimatedParagraph>
            </div>
          </div>
        </div>
      </div>
      <div className="min-h-screen flex items-center justify-center relative">
        <img
          src="https://res.cloudinary.com/dj8ezastp/image/upload//c_thumb,w_1200,h_770,g_auto/v1691066237/Zembo_Storm_bike_1_qiyxzt.jpg"
          alt="Teams"
          className="absolute inset-0 object-cover w-full h-full"
        />

        <div className="absolute inset-0 bg-black opacity-50"></div>

        <div className="p-8 md:-ml-96 text-white z-10">
          <div className="font-bold text-5xl mb-8">Zembo Storm Specs</div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4">
            <div className="flex flex-col items-start">
              <div className="border border-white w-12 mb-2"></div>
              <div className="">Speed</div>
              <div className="text-4xl font-normal">75 km/h</div>
            </div>

            <div className="flex flex-col items-start">
              <div className="border border-white w-12 mb-2"></div>
              <div className="">Range</div>
              <div className="text-4xl font-normal">65 km</div>
            </div>

            <div className="flex flex-col items-start">
              <div className="border border-white w-12 mb-2"></div>
              <div className="">Body</div>
              <div className="text-4xl font-normal md:w-[350px]">
                Comfortable and sturdy
              </div>
            </div>

            <div className="flex flex-col items-start">
              <div className="border border-white w-12 mb-2"></div>
              <div className="">Battery Recharge</div>
              <div className="text-4xl font-normal">Swap (2 mins)</div>
            </div>
          </div>
        </div>
      </div>
      <div className="min-h-screen flex items-center justify-center relative">
        <img
          src="https://res.cloudinary.com/dj8ezastp/image/upload/v1694158871/Battery_lab_team_at_work_5_oimcid.jpg"
          alt="About"
          className="absolute inset-0 object-cover w-full h-full"
        />

        <div className="absolute inset-0 bg-black opacity-50"></div>

        <div className="text-white z-20 mx-8 md:-ml-30 p-8 md:p-0 w-[1080px]">
          <div className="text-2xl md:text-4xl font-bold mb-4">
            A Leading Battery Technology
          </div>
          <div className="text-xl md:text-3xl">
            <AnimatedParagraph>
              Built to last, the Zembo bike is an affordable electric motorcycle
              running on state-of-the-art batteries, granting riders utmost
              comfort and also saving the environment from CO2 emmissions.
            </AnimatedParagraph>
          </div>
        </div>
      </div>
      <div className="min-h-screen flex items-center justify-center relative">
        <img
          src="https://res.cloudinary.com/dj8ezastp/image/upload/v1691066232/2022.06_Masaka_Launch_19_q7gaeb.jpg"
          alt="About"
          className="absolute inset-0 object-cover w-full h-full"
        />

        <div className="absolute inset-0 bg-black opacity-50"></div>

        <div className="text-white z-20 mx-8 md:-ml-30 p-8 md:p-0 w-[1080px]">
          <div className="text-2xl md:text-4xl font-bold mb-4">
            A Customer Centric Solution
          </div>
          <div className="text-xl md:text-3xl">
            <AnimatedParagraph>
              "When I started using my Zembo bike, just in three weeks I was
              able to save up to 500,000 shillings, which did not happen with my
              other fuel bike. I am happy my income has now increased."
              <br />- A Zembo Boda driver
            </AnimatedParagraph>
          </div>
        </div>
      </div>

      <div className="bg-black">
        <div className="flex flex-col md:flex-row items-center">
          <div className="text-white mx-8 md:mx-24 p-8 md:p-0 md:w-[700px]">
            <div className="text-2xl md:text-4xl font-bold mb-4">
              Charge From Anywhere
            </div>
            <div className="text-xl md:text-3xl">
              <AnimatedParagraph>
                With our multiple charging stations across the country, you can
                always swap your battery easily as you enjoy a seamless ride
                powered by solar energy. Zembo fits to represent all the good
                qualities of an eco-friendly motorbike solution.
              </AnimatedParagraph>
            </div>
          </div>
          <div
            className="w-full md:w-1/2 "
            style={{
              overflow: "hidden",
            }}
          >
            <iframe
              loading="lazy"
              src="https://www.google.com/maps/d/embed?mid=1XliuaPA58etzxgPIF0AeYztfE6-FzjDC&ehbc=2E312F"
              width="100%"
              height="800px"
              // frameBorder="0"
              style={{
                position: "relative",
                border: "none",
                width: "100%",
                marginTop: "-60px",
              }}
            ></iframe>
          </div>
        </div>
      </div>

      <OurPartners />
    </MainLayout>
  );
};
