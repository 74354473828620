import React from "react";
import { ClientSlider } from "./ClientSlider";

export const OurPartners = () => {
  return (
    <div className="bg-black text-white">
      <div className="text-2xl md:text-4xl font-bold mb-4 text-center py-8 pt-12">
        Our Partners
      </div>
      <div className="bg-white py-12 px-12 pt-24">
        <ClientSlider />
      </div>
    </div>
  );
};
