import React, { useState, useEffect } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

export const Carousel = ({ images, autoScrollInterval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "ArrowLeft") {
        goToPrevSlide();
      } else if (e.key === "ArrowRight") {
        goToNextSlide();
      }
    };

    const autoScroll = setInterval(goToNextSlide, autoScrollInterval);

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      clearInterval(autoScroll);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []); // Removed autoScrollInterval from the dependency array

  return (
    <div className="relative overflow-hidden">
      <button
        onClick={goToPrevSlide}
        className="absolute top-1/2 left-4 transform -translate-y-1/2 border border-white border-2 p-2 text-white"
      >
        <BsChevronLeft />
      </button>

      <div className="animate__animated animate__fadeInRight">
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          className="w-auto m-auto md:h-[600px]"
        />
      </div>
      <button
        onClick={goToNextSlide}
        className="absolute top-1/2 right-4 transform -translate-y-1/2 border border-white border-2 p-2 text-white"
      >
        <BsChevronRight />
      </button>
    </div>
  );
};
