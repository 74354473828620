import { MainLayout } from "components";
import React from "react";
import { TeamMates } from "../components/TeamMates";

export const Team = () => {
  return (
    <MainLayout>
      <div className="min-h-screen flex items-center justify-center relative">
        <img
          src="https://res.cloudinary.com/dj8ezastp/image/upload/v1694264179/teams_e3fuea.jpg"
          alt="Teams"
          className="absolute inset-0 object-cover w-full h-full"
        />

        <div className="absolute inset-0 bg-black opacity-50"></div>

        <div className="text-white text-center z-10">
          <h1 className="font-black text-5xl mb-4">Meet The Team</h1>
          <h1 className="text-xl md:text-3xl">Hiring Young Local Talent</h1>
        </div>
      </div>
      <div className="bg-black p-6 md:p-12">
        <div className="text-white text-2xl text-center mx-4 md:mx-60 my-12">
          Zembo is a Teal company : we do not have a set hierarchy and we all
          take decisions as owners of the company. Our team gathers young local
          talent working hard for the success of EV in Uganda.
        </div>
      </div>
      <TeamMates />
    </MainLayout>
  );
};
